import * as React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@talentpair/quantic";

export type FeatureProps = {
  variant?: "body2" | "body1";
  text: string;
};

export function Feature({ text, variant = "body1" }: FeatureProps): React.ReactElement {
  return (
    <Box variant="row" layout="center-start" space={2} className="px2">
      <CheckIcon className="mr2" color="primary" />
      <Typography variant={variant}>{text}</Typography>
    </Box>
  );
}
