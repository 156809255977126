import * as React from "react";
import { Divider } from "@mui/material";
import { Typography, TypographyProps, Cast, Box, Button } from "../atoms";
import { Feature } from "./Feature";

export type PricingPlanCardProps = {
  name: string;
  monthlyFee: string;
  promo?: string;
  serviceLevel?: string;
  bestForHires?: string;
  color: TypographyProps["color"];
  standardActivationFee: string;
  standardPlacementFee: string;
  featuresLabel?: string;
  features?: string[];
  cta?: string;
  ctaProps?: Record<string, unknown>;
  style?: React.CSSProperties;
  activeJobsLimit?: number | null; // not really used, but gets spread on
};

export function PricingPlanCard({
  name,
  monthlyFee = "$2,000",
  promo = "",
  serviceLevel = "",
  color = "blue.500" as TypographyProps["color"],
  standardPlacementFee = "$17,000",
  standardActivationFee = "$299",
  featuresLabel = "Features include:",
  features = [],
  cta = "Select plan",
  activeJobsLimit, // pulled out to ignore
  ctaProps,
  ...props
}: PricingPlanCardProps): React.ReactElement {
  return (
    <Box
      variant="column"
      layout="stretch-start"
      space={4}
      className="p5 box-shadow2 br2"
      {...props}
    >
      <Box variant="row" layout="center-between" space={5} className="pr2">
        <Typography variant="h2" color={color}>
          {name}
        </Typography>
        {promo ? (
          <Typography variant="body1" bold color={color}>
            {promo}
          </Typography>
        ) : null}
      </Box>

      <Box variant="row" layout="start-start">
        <Typography variant="body1" color={color} noWrap>
          {serviceLevel}
        </Typography>
      </Box>

      <Box variant="column" layout="start-start" space={1} className="mtn2">
        {name === "Custom" ? (
          <Typography variant="h1">Custom pricing</Typography>
        ) : (
          <Box variant="row" layout="end-start" space={1}>
            <Typography variant="h1">{monthlyFee}</Typography>
            <Typography variant="body1" style={{ paddingBottom: 4 }} noWrap>
              / month
            </Typography>
          </Box>
        )}
      </Box>

      <Box variant="column" layout="stretch-start" space={3}>
        <Box variant="column" layout="stretch-start" space={1}>
          <Box
            variant="row"
            layout="center-between"
            space={2}
            background="grey.50"
            className="py1 px2"
            style={{ borderRadius: 2, minHeight: 28 }}
          >
            <Typography variant="body2" color="grey.800">
              {standardActivationFee ? "Job posting fee" : ""}
            </Typography>
            <Typography variant="body2" color="grey.800">
              {standardActivationFee}
            </Typography>
          </Box>
          <Box
            variant="row"
            layout="center-between"
            space={2}
            background="grey.50"
            className="py1 px2"
            style={{ borderRadius: 2, minHeight: 28 }}
          >
            <Typography variant="body2" color="grey.800">
              {standardPlacementFee ? "Success fee" : ""}
            </Typography>
            <Typography variant="body2" color="grey.800">
              {standardActivationFee ? <Cast color="grey.500">up to</Cast> : ""}{" "}
              {standardPlacementFee}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider className="mtn1 bg-grey-300 mb3" />
      {features.length ? (
        <Box variant="column" layout="stretch-start" space={3}>
          <Typography variant="overline" className="px2">
            {featuresLabel}
          </Typography>
          {features.map((f) => (
            <Feature key={f} variant="body2" text={f} />
          ))}
        </Box>
      ) : null}
      {ctaProps ? (
        <Box variant="column" layout="center-end" flex="auto">
          <Button variant="contained" {...ctaProps}>
            {cta}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
