import { useMediaQuery } from "@mui/material";

export type PricingCardLayout = {
  desktop: boolean;
  cardWidth: string;
  cardHeight: string;
};

export function useResponsivePricingCards(): PricingCardLayout {
  // NOTE: If you edit these numbers be sure to also update iframe styles in:
  // mumbai/deployments/pricing/README.md
  // mumbai/deployments/london/src/shared-apps/Qa/components/Qa.tsx
  // Iframe width/height should be the combined width/heights of all cards and padding such that there is no inner scroll box
  const desktop = useMediaQuery("(min-width: 826px)");
  return desktop
    ? {
        desktop,
        cardWidth: "385px",
        cardHeight: "600px",
      }
    : {
        desktop,
        cardWidth: "100%",
        cardHeight: "600px",
      };
}
