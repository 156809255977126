/* eslint-disable import/first */
// CSS
import "normalize.css";
import "@talentpair/assets/css/main.css";
import "@talentpair/assets/css/basscss";
import { sentry } from "@talentpair/sentry";
import ReactDOM from "react-dom";
import App from "./App";

sentry.init();

ReactDOM.render(<App />, document.getElementById("react-app"));
