import * as React from "react";
import { StyleProps } from "@talentpair/types/component";
import { styled } from "@mui/material/styles";
import { TalentpairTheme } from "../theme/types";
import { colorProp, ColorPropT } from "../theme/colorProp";

export type CastProps = React.PropsWithChildren<
  StyleProps & {
    color?: ColorPropT;
    bold?: boolean;
    italic?: boolean;
    strike?: boolean;
    underline?: boolean;
  }
>;

export const Cast = styled(
  ({ bold, italic, strike, underline, color, ...props }: CastProps) => <span {...props} />,
  { skipSx: true },
)(
  ({
    bold,
    italic,
    strike,
    underline,
    color = "inherit",
    theme,
  }: CastProps & { theme?: TalentpairTheme }) => ({
    color: colorProp(color, theme as TalentpairTheme),
    fontWeight: bold ? 700 : bold === false ? 400 : "inherit",
    fontStyle: italic ? "italic" : "inherit",
    textDecoration: strike ? "line-through" : underline ? "underline" : "inherit",
  }),
);
