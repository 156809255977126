import * as React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ErrorBoundary, withProfiler, sentry } from "@talentpair/sentry";
import { env } from "@talentpair/env";
import { tpTheme } from "@talentpair/quantic";
import { PricingPlanCards } from "@talentpair/quantic/pricing";

function App(): React.ReactElement | null {
  const onSelectPlan = (price_id: string): void => {
    const signupUrl = env.londonUrl(`/employer-signup?plan_id=${price_id}`);
    try {
      window.parent?.location.assign(signupUrl);
    } catch (e) {
      sentry.error(e as Error);
      window.open(signupUrl, "_blank")?.focus();
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={tpTheme}>
        <ErrorBoundary>
          <CssBaseline />
          <PricingPlanCards onSelectPlan={onSelectPlan} />
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default withProfiler(App);
