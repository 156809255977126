import * as React from "react";
import { mailToLink } from "@talentpair/quantic";
import { apiRequest, useFetcher } from "@talentpair/api";
import { setTpCookie } from "@talentpair/tracking";
import { displayDollars } from "kyoto/utils/string";
import { PricingPlanType } from "kyoto/utils/entities/misc";
import { Box } from "../atoms";
import { PricingPlanCard, PricingPlanCardProps } from "./PricingPlanCard";
import { useResponsivePricingCards } from "./useRepsonsivePricingCards";

function displayDollarsOrNA(num: number | null | undefined): string {
  return num ? displayDollars(num) : "n/a";
}

export const detailsForPlanType = {
  [PricingPlanType.PARTNERSHIP]: {
    // OLD MANUAL BILLING PLANS
    color: "primary" as PricingPlanCardProps["color"],
    serviceLevel: "ATS + Dedicated recruiting services",
    billingPeriod: "Billed monthly",
    dedicatedRecruiters: true,
    features: [
      "Sourced and vetted candidate matches",
      "Unlimited job posts",
      "Unlimited team seats",
      "Branded job boards",
      "Reporting tools",
    ],
  },
  [PricingPlanType.SOURCING]: {
    // OLD STRIPE PLAN
    color: "triadic" as PricingPlanCardProps["color"],
    serviceLevel: "ATS + Dedicated recruiting services",
    bestForHires: "",
    billingPeriod: "Billed monthly",
    dedicatedRecruiters: false,
    features: [
      "Sourced and vetted candidate matches",
      "Unlimited job posts",
      "Unlimited team seats",
      "Branded job boards",
      "Reporting tools",
    ],
  },
  [PricingPlanType.ACCESS]: {
    // OLD STRIPE PLAN
    color: "blue.500" as PricingPlanCardProps["color"],
    serviceLevel: "ATS + Dedicated recruiting services",
    bestForHires: "1-2",
    billingPeriod: "Billed monthly",
    dedicatedRecruiters: true,
    features: [
      "Sourced and vetted candidate matches",
      "Unlimited job posts",
      "Unlimited team seats",
      "Branded job boards",
      "Reporting tools",
    ],
  },
  [PricingPlanType.PLATFORM]: {
    color: "primary" as PricingPlanCardProps["color"],
    serviceLevel: "ATS + Dedicated recruiting services",
    bestForHires: "",
    billingPeriod: "Billed monthly",
    dedicatedRecruiters: true,
    features: [
      "Sourced and vetted candidate matches",
      "Unlimited job posts",
      "Unlimited team seats",
      "Branded job boards",
      "Reporting tools",
    ],
  },
  [PricingPlanType.CUSTOM]: {
    color: "triadic" as PricingPlanCardProps["color"],
    serviceLevel: "Flexible options",
    bestForHires: "",
    billingPeriod: "Billed monthly",
    dedicatedRecruiters: true,
    features: [
      "Contingency searches",
      "Executive searches",
      "Recruiting managerial services",
      "Hiring strategy",
      "Custom reporting",
    ],
  },
};

type StripeFee = { description: string; amount: number };

type StripePlan = {
  id: string; // price id
  product: {
    plan_type: number;
    month_price: number;
    billing_period: string;
    hiring_fees: Array<StripeFee>;
    retainer_fees: Array<StripeFee>;
    features: Array<string>;
  };
};

type PlanCardData = Pick<
  PricingPlanCardProps,
  "monthlyFee" | "standardPlacementFee" | "standardActivationFee" | "features"
> & { priceId: string };

function stripeProductToCardData(plan: StripePlan): PlanCardData {
  return {
    priceId: plan.id,
    monthlyFee: displayDollarsOrNA(plan.product.month_price),
    standardPlacementFee: displayDollarsOrNA(plan.product.hiring_fees?.[0]?.amount),
    standardActivationFee: displayDollarsOrNA(plan.product.retainer_fees[0]?.amount),
    features: plan.product.features
      ? plan.product.features.reduce((list, f) => {
          f = f.trim();
          if (f) list.push(`${f.slice(0, 1).toUpperCase()}${f.slice(1)}`);
          return list;
        }, [] as string[])
      : [],
  };
}

export type SupportedPlanTypes = "platform" | "custom";

export function isSupportedPlanType(v: string): v is SupportedPlanTypes {
  return ["platform"].includes(v);
}

export async function getStripeProducts(): Promise<Record<SupportedPlanTypes, StripePlan>> {
  const { data } = await apiRequest.get<StripePlan[]>("payments/stripe-prices/");
  const platform = data.find((p) => p.product.plan_type === PricingPlanType.PLATFORM);
  const custom = data.find((p) => p.product.plan_type === PricingPlanType.CUSTOM);
  if (!platform || !custom) throw new Error("Missing some pricing plan data!");
  return { platform, custom };
}

export function PricingPlanCards({
  onSelectPlan,
}: {
  onSelectPlan?: (price_id: string, plan_type: SupportedPlanTypes) => unknown;
}): React.ReactElement {
  const { rsp, loading } = useFetcher(() =>
    getStripeProducts().then(({ platform }) => ({
      platform: stripeProductToCardData(platform),
    })),
  );

  const { desktop, cardHeight, cardWidth } = useResponsivePricingCards();
  const cardStyle = { width: cardWidth, height: cardHeight };

  const fallback: PlanCardData = {
    priceId: "",
    monthlyFee: "",
    standardPlacementFee: "",
    standardActivationFee: "",
    features: [
      "Sourced and vetted candidate matches",
      "Unlimited job posts",
      "Unlimited team seats",
      "Branded job boards",
      "Reporting tools",
    ],
  };

  return (
    <Box
      variant={desktop ? "row" : "column"}
      layout={desktop ? "start-center" : "stretch-start"}
      space={desktop ? 6 : 4}
      style={{ width: "100%", height: "100%" }}
      className="p4"
    >
      <PricingPlanCard
        name="Platform"
        {...(rsp?.platform || fallback)}
        {...detailsForPlanType[PricingPlanType.PLATFORM]}
        cta="Select plan"
        ctaProps={
          loading || !rsp
            ? undefined
            : onSelectPlan
            ? {
                "data-testid": "platformPlanBtn",
                color: "primary",
                onClick: () => {
                  setTpCookie("TP_PRICE_ID", rsp.platform.priceId, false);
                  onSelectPlan?.(rsp.platform.priceId, "platform");
                },
              }
            : {
                disabled: true,
              }
        }
        style={cardStyle}
      />
      <PricingPlanCard
        name="Custom"
        promo="Talk to us!"
        featuresLabel="Flexible options include:"
        {...(rsp?.platform || fallback)}
        {...detailsForPlanType[PricingPlanType.CUSTOM]}
        monthlyFee=""
        standardPlacementFee=""
        standardActivationFee=""
        cta="Let's chat"
        ctaProps={{
          "data-testid": "customPlanBtn",
          target: "_blank",
          href: mailToLink("sales@talentpair.com", {
            subject: "I'm interested in custom service from Talentpair.",
          }),
        }}
        style={cardStyle}
      />
    </Box>
  );
}

export const _test = { stripeProductToCardData };
